import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { Box, ThemeProvider } from "@mui/system";
import { createTheme } from "@mui/material/styles";
import { formatearNumero } from "../Auxiliares/Helpers";
import AxiosApi from "./../../servicios/axios.service";
import Fab from "@mui/material/Fab";
import ReplayIcon from "@mui/icons-material/Replay";
import { DateTime } from "luxon";
const VentasTiendasComponente = ({ codalm, dia }) => {

  let fecha;
  if (dia=="hoy"){
    fecha=DateTime.now().setLocale('es').toFormat("EEEE, dd LLLL yyyy")
  } else {
    fecha=DateTime.now().plus({days:-1}).setLocale('es').toFormat("EEEE, dd LLLL yyyy")
  }
  const [fechatexto, setfechatexto] = React.useState(fecha);
  const [count, setCount] = React.useState(0);
  const [counttickets, setCounttickets] = React.useState(0);
  const [ventas, setventas] = React.useState(0.0);
  const [tickets, setTickets] = React.useState(0);
  const [ventassp, setventassp] = React.useState(0.0);
  const [ticketssp, setTicketssp] = React.useState(0);
  const [ticketmedio, setTicketmedio] = React.useState(0.0);
  const [porcentajeventas, setPorcentajeventas] = React.useState(0.0);
  const [porcentajetickets, setPorcentajetickets] = React.useState(0.0);
  const [datos, setDatos] = React.useState();
  const duration = 2.1; // segundos
  const tiendas = [];
  tiendas["V"] = "Vargas";
  tiendas["S"] = "Sardinero";
  const theme = createTheme({
    palette: {
      primary: {
        main: "rgba(51, 204, 153, 0.35)",
      },
      background: {
        paper: "#fff",
        vargas: "rgba(204, 51, 204, 0.55)",
        sardinero: "rgba(51, 204, 153, 0.65)",
        fab: "#e3307e",
      },
      text: {
        primary: "#173A5E",
        secondary: "#46505A",
      },
      action: {
        active: "#001E3C",
      },
      success: {
        dark: "#009688",
        main: "rgba(51, 204, 153, 0.35)",
      },
      error: {
        dark: "#c62828",
        main: "#d32f2f",
      },
    },
  });
  //   console.log(theme)
  const fetchData = async () => {
    setventas(0.0);
    setTickets(0);
    const url =
      dia == "hoy"
        ? "https://empleados.diferente.es/api/ventashoy?codalm=" + codalm
        : "https://empleados.diferente.es/api/ventasayer?codalm=" + codalm;
    try {
      const response = await AxiosApi.axiosApi.get(url);
      console.log(response.data.SPSUMA ? response.data.SPSUMA : 1);
    const fechaap=DateTime.fromFormat(response.data.apfecha,'yyyyLLdd').setLocale('es');
    const fechaapformato=fechaap.toFormat("EEEE, dd LLLL yyyy")

    const fechamdap=DateTime.fromFormat(response.data.mdapfecha,'yyyyLLdd').setLocale('es');
    const fechamdapformato=fechamdap.toFormat("EEEE, dd LLLL yyyy")

    const fechasp=DateTime.fromFormat(response.data.spfecha,'yyyyLLdd').setLocale('es');
    const fechaspformato=fechasp.toFormat("EEEE, dd LLLL yyyy")

    response.data.apfecha=fechaapformato
    response.data.spfecha=fechaspformato
    response.data.mdapfecha=fechamdapformato
    // console.log(fechaapformato)

      setDatos(response.data);
      const variacionventas =
        ((response.data.SUMA ? response.data.SUMA : 0) /
          (response.data.SPSUMA ? response.data.SPSUMA : 1) -
          1) *
        100;
      const variaciontickets =
        ((response.data.numerotickets ? response.data.numerotickets : 0) /
          (response.data.spnumerotickets ? response.data.spnumerotickets : 1) -
          1) *
        100;
      setventas(response.data.SUMA ? response.data.SUMA : 0);
      setventassp(
        response.data.SPSUMA ? formatearNumero(response.data.SPSUMA) : 0
      );
      setTickets(response.data.numerotickets ? response.data.numerotickets : 0);
      setTicketssp(
        response.data.spnumerotickets ? response.data.spnumerotickets : 0
      );
      setPorcentajeventas(variacionventas.toFixed(2));
      setPorcentajetickets(variaciontickets.toFixed(2));

      // console.log(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  React.useEffect(() => {
    fetchData();
  }, []);

  React.useEffect(() => {
    let start = 0;
    const end = ventas;
    const incrementTime = (1 / end) * 1000;
    const startTime = performance.now();
    const increment = 0.01;
    const totalIncrements = end / increment;
    const timePerIncrement = (duration * 1000) / totalIncrements;

    const step = (timestamp) => {
      const progress = timestamp - startTime;
      const newCount = Math.min(
        end,
        (progress / (duration * 1000)) * end
      ).toFixed(2);
      setCount(formatearNumero(newCount));
      if (parseFloat(newCount) < end) {
        requestAnimationFrame(step);
      }
    };

    requestAnimationFrame(step);

    return () => setCount(0); // Reset count if component unmounts
  }, [ventas]);
  React.useEffect(() => {
    let start = 0;
    const end = tickets;
    const incrementTime = (1 / end) * 1000;
    const startTime = performance.now();
    const increment = 1;
    const totalIncrements = end / increment;
    const timePerIncrement = (duration * 1000) / totalIncrements;

    const step = (timestamp) => {
      const progress = timestamp - startTime;
      const newCount = Math.min(end, (progress / (duration * 1000)) * end);
      setCounttickets(formatearNumero(newCount.toFixed(0)));
      if (parseFloat(newCount) < end) {
        requestAnimationFrame(step);
      }
    };

    requestAnimationFrame(step);

    return () => setCount(0); // Reset count if component unmounts
  }, [tickets]);
  return (
    <>
      {/* <div style={{textAlign:'center'}} className={codalm=='V'?'card_home_vargas':'card_home_sardinero'}>
        <Fab
              onClick={() => {
                fetchData();
              }}
              sx={{ position: 'relative', top: 5, left: '45%' }}
              size="small"
              color="#e3007e"
              aria-label="refresh"
            >
              <ReplayIcon />
            </Fab>
            <Typography variant="h4">Ventas de hoy en {tiendas[codalm]}</Typography>
      <Typography variant="h2">{count} €</Typography>
      <Typography variant="h2">{counttickets} Tickets</Typography>
    </div> */}

      <ThemeProvider theme={theme}>
        <Box
          className={codalm == "V" ? "card_home_vargas" : "card_home_sardinero"}
          sx={{
            bgcolor: "background.vargas",
            ...(codalm == "S" && { bgcolor: "background.sardinero" }),
            boxShadow: 1,
            borderRadius: 2,
            p: 2,
            //   minHeight: 250,
          }}
        >
          <Box
            sx={{ color: "text.secondary", fontSize: 22, fontWeight: "medium" }}
          >
            Ventas de {dia} en {tiendas[codalm]}
          </Box>
          <Box
            sx={{ color: "text.secondary", fontSize: 14, fontWeight: "medium" }}
          >
            {fechatexto}
          </Box>
          <Box
            sx={{
              color: "text.primary",
              fontSize: 34,
              fontWeight: "medium",
              display: "inline",
            }}
          >
            {count} €
          </Box>
          <Box></Box>
        
       
          <Box
            sx={{
              color: "error.dark",
              ...(porcentajeventas >= 0 && { color: "success.dark" }),
              display: "inline",
              fontWeight: "bold",
            //   mx: 0.1,
              fontSize: 14,
            }}
          >
            {porcentajeventas} %
          </Box>

          <Box
            sx={{ color: "text.secondary", display: "inline", mx:0.5,fontSize: 14 }}
          >
            vs. semana pasada: {datos && datos.spfecha}
          </Box>
          <Box
            sx={{
              color: "text.secondary",
              mx: 0.5,
              display: "inline",
              fontSize: 14,
            }}
          >({ventassp} €)
          </Box>
          <Box></Box>

{/* // Mismo días de la semana del año pasado */}

{
    datos && datos.APSUMA &&
    <>
            <Box></Box>
    
       
          <Box
            sx={{
              color: "error.dark",
              ...(formatearNumero( ((datos.SUMA/datos.APSUMA)-1)*100) >= 0 && { color: "success.dark" }),
              display: "inline",
              fontWeight: "bold",
            //   mx: 1,
              fontSize: 14,
            }}
          >
            {formatearNumero( ((datos.SUMA/datos.APSUMA)-1)*100)} %
          </Box>

          <Box
            sx={{ color: "text.secondary", display: "inline", mx:0.5,fontSize: 14 }}
          >
            vs. mismo día de la semana del año pasado {datos.apfecha}
          </Box>
          <Box
            sx={{
              color: "text.secondary",
              mx: 0.5,
              display: "inline",
              fontSize: 14,
            }}
          >({formatearNumero(datos.APSUMA)} €)
          </Box>
          <Box></Box>
    </>
}

{/* // Mismo día de del año pasado */}

{
    datos && datos.MDAPSUMA &&
    <>
            <Box></Box>
    
       
          <Box
            sx={{
              color: "error.dark",
              ...(formatearNumero( ((datos.SUMA/datos.MDAPSUMA)-1)*100) >= 0 && { color: "success.dark" }),
              display: "inline",
              fontWeight: "bold",
            //   mx: 1,
              fontSize: 14,
            }}
          >
            {formatearNumero( ((datos.SUMA/datos.MDAPSUMA)-1)*100)} %
          </Box>

          <Box
            sx={{ color: "text.secondary", display: "inline", mx:0.5,fontSize: 14 }}
          >
            vs. mismo día del año pasado {datos.mdapfecha}
          </Box>
          <Box
            sx={{
              color: "text.secondary",
              mx: 0.5,
              display: "inline",
              fontSize: 14,
            }}
          >({formatearNumero(datos.MDAPSUMA)} €)
          </Box>
          <Box></Box>
    </>
}






          <Box
            sx={{
              color: "text.primary",
              fontSize: 34,
              display: "inline",
              fontWeight: "medium",
            }}
          >
            {counttickets} Tickets
          </Box>
        
          
          <Box></Box>
          <Box
            sx={{
              color: "error.dark",
              ...(porcentajetickets >= 0 && { color: "success.dark" }),
              display: "inline",
              fontWeight: "bold",
            //   mx: 1,
              fontSize: 14,
            }}
          >
            {porcentajetickets} %
          </Box>
          <Box
            sx={{ color: "text.secondary", display: "inline", mx:0.5,fontSize: 14 }}
          >
           vs. semana pasada: {datos && datos.spfecha}
          </Box>
          <Box
            sx={{
              color: "text.secondary",
            //   mx: 1.5,
              display: "inline",
              fontSize: 14,
            }}
          >
            ({ticketssp} tickets)
          </Box>
          <Box></Box>
          <>

{/* // Mismo días de la semana del año pasado */}

{
    datos && datos.APSUMA &&
    <>
            <Box></Box>
          
       
          <Box
            sx={{
              color: "error.dark",
              ...(formatearNumero( ((datos.numerotickets/datos.apnumerotickets)-1)*100) >= 0 && { color: "success.dark" }),
              display: "inline",
              fontWeight: "bold",
            //   mx: 1,
              fontSize: 14,
            }}
          >
            {formatearNumero( ((datos.numerotickets/datos.apnumerotickets)-1)*100)} %
          </Box>

          <Box
            sx={{ color: "text.secondary", display: "inline", mx:0.5,fontSize: 14 }}
          >
            vs. mismo día de la semana del año pasado: {datos.apfecha}
          </Box>
          <Box
            sx={{
              color: "text.secondary",
              mx: 1.5,
              display: "inline",
              fontSize: 14,
            }}
          >({formatearNumero(datos.apnumerotickets)} tickets)
          </Box>
          <Box></Box>
    </>
}

{/* // Mismo día del año pasado */}

{
    datos && datos.MDAPSUMA &&
    <>
            <Box></Box>
          
       
          <Box
            sx={{
              color: "error.dark",
              ...(formatearNumero( ((datos.numerotickets/datos.mdapnumerotickets)-1)*100) >= 0 && { color: "success.dark" }),
              display: "inline",
              fontWeight: "bold",
            //   mx: 1,
              fontSize: 14,
            }}
          >
            {formatearNumero( ((datos.numerotickets/datos.mdapnumerotickets)-1)*100)} %
          </Box>

          <Box
            sx={{ color: "text.secondary", display: "inline", mx:0.5,fontSize: 14 }}
          >
            vs. mismo día de la semana del año pasado: {datos.mdapfecha}
          </Box>
          <Box
            sx={{
              color: "text.secondary",
              mx: 1.5,
              display: "inline",
              fontSize: 14,
            }}
          >({formatearNumero(datos.mdapnumerotickets)} tickets)
          </Box>
          <Box></Box>
    </>
}




            {datos && (
              <>
              <hr></hr>
                <Box
                  sx={{
                    color: "text.secondary",
                    display: "inline",
                    fontSize: 14,
                  }}
                >
                  Por la mañana:
                </Box>
                <Box></Box>
                <Box
                  sx={{
                    color: "text.secondary",
                    display: "inline",
                    fontSize: 14,
                  }}
                >
                  {formatearNumero(datos.ventasmatutinas)} € (
                  {formatearNumero((datos.ventasmatutinas / datos.SUMA) * 100)}{" "}
                  %)
                  <p></p>
                  {formatearNumero(datos.ticketsmatutinos)} tickets (
                  {formatearNumero(
                    (datos.ticketsmatutinos / datos.numerotickets) * 100
                  )}{" "}
                  %)
                </Box>

                <Box></Box>
                <hr></hr>
                <Box></Box>
                <Box
                  sx={{
                    color: "text.secondary",
                    display: "inline",
                    fontSize: 14,
                  }}
                >
                  Por la tarde:
                </Box>
                <Box></Box>
                <Box
                  sx={{
                    color: "text.secondary",
                    display: "inline",
                    fontSize: 14,
                  }}
                >
                  {formatearNumero(datos.SUMA - datos.ventasmatutinas)} € (
                  {formatearNumero(((datos.SUMA - datos.ventasmatutinas) / datos.SUMA) * 100)}{" "}
                  %)
                  <p></p>
                  {formatearNumero(datos.numerotickets-datos.ticketsmatutinos)} tickets (
                  {formatearNumero(
                    ((datos.numerotickets-datos.ticketsmatutinos) / datos.numerotickets) * 100
                  )}{" "}
                  %)
                </Box>

                <Box>
                  <Fab
                    onClick={() => {
                      fetchData();
                    }}
                    sx={{ position: "relative", top: 5, left: "89%" }}
                    size="small"
                    color="background.fab"
                    // backgroundColor="background.fab"
                    aria-label="refresh"
                  >
                    <ReplayIcon />
                  </Fab>
                </Box>
              </>
            )}
          </>
        </Box>
      </ThemeProvider>
    </>
  );
};

export default VentasTiendasComponente;
