import React from "react";
import VentasTiendasComponente from "./VentasTiendasComponente";
import { Box, Grid } from "@material-ui/core";
// import Grid from '@material-ui/core'
const Ventasdiarias = () => {
  return (

      <Grid item justifyContent="center" container >
        <Grid item style={{ padding: "0.3rem" }} xs={12} sm= {6} md={6} lg={3}>
          {" "}
          <VentasTiendasComponente codalm={"V"} dia={'ayer'}/>
        </Grid>
        <Grid item style={{ padding: "0.3rem" }} xs={12} sm= {6} md={6} lg={3}>
          {" "}
          <VentasTiendasComponente codalm={"V"} dia={'hoy'}/>
        </Grid>
        <Grid item style={{ padding: "0.3rem" }} xs={12} sm= {6} md={6} lg={3}>
          {" "}
          <VentasTiendasComponente codalm={"S"} dia={'ayer'} />
        </Grid>
        <Grid item style={{ padding: "0.3rem" }} xs={12} sm= {6} md={6} lg={3}>
          {" "}
          <VentasTiendasComponente codalm={"S"} dia={'hoy'} />
        </Grid>
      </Grid>
  
  );
};

export default Ventasdiarias;
