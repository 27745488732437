import React, { useState, useEffect, useRef } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";
import { Link ,useNavigate} from "react-router-dom";
import AuthService from "./../servicios/auth.service";
import LogoPlacer from './../recursos/placer.png'
import cebra from './../recursos/cebra-magenta.png'
const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};
const validEmail = (value) => {
  if (!isEmail(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        This is not a valid email.
      </div>
    );
  }
};

const Login = (props) => {
  const form = useRef();
  const checkBtn = useRef();

  const [usuario, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const navigate= useNavigate()
  const onChangeUsername = (e) => {
    const usuario = e.target.value;
    setUsername(usuario);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };
  useEffect(() => {
    document.title = "Acceso"
  }, []);
  const handleLogin = (e) => {
    e.preventDefault();

    setMessage("");
    setLoading(true);

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      AuthService.login(usuario, password).then(
        () => {
          navigate("/");
          // window.location.reload();
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          setLoading(false);
          setMessage(resMessage);
        }
      );
    } else {
      setLoading(false);
    }
  };

  return (
      <div className="col-xs-12">
          {/* <Link to={"/"} className="">
          Principal
        </Link> */}
          <div
              // style="background-color: rgba(0, 0, 0, 0.125);"
              className="card card-container"
          >
              <img
                  // src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
                  src={LogoPlacer}
                  alt="profile-img"
                  className="profile-img-card"
              />

              <Form onSubmit={handleLogin} ref={form}>
                  <div className="form-group">
                      <label htmlFor="usuario">usuario</label>

                      <Input
                          type="text"
                          className="form-control"
                          name="usuario"
                          value={usuario}
                          onChange={onChangeUsername}
                          validations={[required]}
                      />
                  </div>

                  <div className="form-group">
                      <label htmlFor="password">Password</label>
                      <Input
                          type="password"
                          className="form-control"
                          name="password"
                          value={password}
                          onChange={onChangePassword}
                          validations={[required]}
                      />
                  </div>

                  <div className="form-group">
                      <button
                          className="btn btn-primary btn-block"
                          disabled={loading}
                      >
                          {loading && (
                              <span className="spinner-border spinner-border-sm"></span>
                          )}
                          <span>Login</span>
                      </button>
                  </div>
                  {/* <a href="https://www.youtube.com/watch?v=WLkeANKdlSo"> */}
                  <a
                      target="_blank"
                      // href="https://www.youtube.com/watch?v=kuQvHMl5JGo"
                      href="https://www.youtube.com/watch?v=WLkeANKdlSo"
                      title="La cebra tiene respuestas"
                  >
                      <img
                          // src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
                          src={cebra}
                          alt="La cebra tiene respuestas"
                          className="cebra"
                      />
                  </a>

                  {message && (
                      <div className="form-group">
                          <div className="alert alert-danger" role="alert">
                              {message}
                          </div>
                      </div>
                  )}
                  <CheckButton style={{ display: "none" }} ref={checkBtn} />
              </Form>
          </div>
      </div>
  );
};

export default Login;