import React from 'react'
import cebra from './../../recursos/cebra-magenta.png'
function CargandoDatos({cargando}) {
    React.useEffect(() => {
        // const interval = setInterval(() => {
        //   setIsSpinning((prev) => !prev);
        // }, 500);
    
        // return () => clearInterval(interval);
      }, []);

  return (
    <>
    {
        cargando
        ?
        (<div
        className='mover'
        style={{
            textAlign:'center'
        }}>
            <img
            className='rotate'
              src={cebra}
              alt="Cargando..."
              style={{ maxWidth:'120px'}}
            />
          </div>)
        :
        (<></>)
    }
    </>
  )
}

export default CargandoDatos