import React from "react";
import { Box, Grid, makeStyles } from "@material-ui/core";
import Fab from "@mui/material/Fab";
import ReplayIcon from "@mui/icons-material/Replay";
import Typography from "@mui/material/Typography";
import AxiosApi from "./../../servicios/axios.service";
import DataTable from "react-data-table-component";
import CargandoDatos from "../Auxiliares/CargandoDatos";
import IconButton from "@mui/material/IconButton";
import PrintIcon from "@mui/icons-material/Print";
import {
  colores,
  // conditionalRowStyles,
  customStyles,
} from "../Auxiliares/Helpers";

const Elaboraciones = () => {
  const [elaboracion, setElaboraciones] = React.useState([]);
  const [cargando, setCargando] = React.useState(false);
  const conditionalRowStyles = [
    {
      when: (row) => row.CODALMFAB == "S",
      style: () => ({ backgroundColor: "rgba(51, 204, 153, 0.35)" }),
    },
    {
      when: (row) => row.CODALMFAB == "V",
      style: () => ({ backgroundColor: "rgba(204, 51, 204, 0.35)" }),
    },
  ];
  const traduccion= { 
    rowsPerPageText: 'Filas por página:', 
    rangeSeparatorText: 'de', 
    noRowsPerPage: false, 
    selectAllRowsItem: false, 
    selectAllRowsItemText: 'Tod@s' }
  const Reimprimir = (codalm,idlinp,lote,codigo,unidades)=>{
    setCargando(true)
    console.log(codalm, idlinp)
    let data ={
        codalm:codalm,
        idlinp:idlinp,
        lote:lote,
        codigo:codigo,
        unidades:unidades,
    }
    AxiosApi.axiosApi.post(
        "https://empleados.diferente.es/api/elaboraciones/reimprimir",data
      )
    .then((response)=>{
        console.log(response)
        setCargando(false)
        if(response.message){
            console.log(response.response.status)
        }
        if (response.status===200){
            console.log(response)
        }

    })
    .catch(err=>{
        console.log(err)
    })
  }
  const columns = [
    {
      name: "Código",
      selector: (row) => row.codigo,
      sortable: true,
      //   maxWidth: "20px",
      //   maxWidth: '2rem',
      width: "12%",
      // added line here
      // width: "90px",
      style: {
        flex: "unset",
      },
      headerStyle: (selector, id) => {
        return { textAlign: "center" }; // removed partial line here
      },
    },
    {
      name: "Artículo",
      selector: (row) => row.descart,
      sortable: true,
      width: "30%",
      // width: "250px",
      //   maxWidth: "60px",
      //   grow: 6,
      cell: (row) => {
        return (
          <div
            style={{ paddingLeft: 0, fontSize: "0.8rem", textAlign: "left" }}
          >
            {row.descart}
          </div>
        );
      },
    },
    {
      name: "Uds",
      selector: (row) => row.UNIDADESF,
      sortable: true,
      //   maxWidth: "20px",
      //   maxWidth: '2rem',
      width: "8%",
      // added line here
      // width: "90px",
      style: {
        flex: "unset",
      },
      headerStyle: (selector, id) => {
        return { textAlign: "center" }; // removed partial line here
      },
      cell: (row) => (
        <div
          style={{
            //    fontSize: "0.8rem",
            whiteSpace: "pre-wrap",
            wordWrap: "break-word",
            textAlign: "center",
          }}
        >
          {parseFloat(row.UNIDADESF).toFixed(0)}
        </div>
      ),
    },
    {
      name: "Fecha",
      selector: (row) => row.FECHAFIN,
      sortable: true,
      width: "14%",
      // maxWidth: "20px",
      // grow: 3,
      // headerStyle: (selector, id) => {
      //   return { textAlign: "center"}; // removed partial line here
      // },
      // width: "120px",
      //   grow: 4,
      cell: (row) => (
        <div
          style={{
            //    fontSize: "0.8rem",
            whiteSpace: "pre-wrap",
            wordWrap: "break-word",
          }}
        >
          {new Date(row.FECHAFIN).toLocaleString("es-ES", {
            year: "2-digit",
            month: "2-digit",
            day: "numeric",
          })}
        </div>
      ),
    },
    {
      name: "Empleado",
      selector: (row) => row.PARAM1,
      sortable: true,
      width: "25%",
      // width: "250px",
      //   maxWidth: "60px",
      //   grow: 6,
      cell: (row) => {
        return (
          <div
            style={{ paddingLeft: 0, fontSize: "0.8rem", textAlign: "left" }}
          >
            {row.PARAM1}
          </div>
        );
      },
    },
    {
      width: "5%",
      cell: (row) => {
        return (
          <IconButton 
          onClick={() => {
            Reimprimir(row.CODALMFAB, parseInt(row.idlinp),row.lote,row.codigo, parseInt(row.UNIDADESF));
          }}
          aria-label="Reimprimir">
            <PrintIcon />
          </IconButton>
        );
      },
    },
  ];

  const fetchData = async () => {
    setCargando(true);
    try {
      const response = await AxiosApi.axiosApi.get(
        "https://empleados.diferente.es/api/elaboraciones/lista"
      );
      setElaboraciones(response.data);
      setCargando(false);
      // console.log(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  React.useEffect(() => {
    fetchData();
  }, []);
  return (
    <>
      {!cargando ? (
        <div className="card_home">
          <Box sx={{ width: "99%" }}>
            <Fab
              onClick={() => {
                fetchData();
              }}
              sx={{ position: "relative", top: 16, left: "90%" }}
              size="small"
              color="primary"
              aria-label="add"
            >
              <ReplayIcon />
            </Fab>
            <Typography variant="h6">
              Elaboraciones con pegatinas de los últimos 15 días
            </Typography>
          </Box>
          <DataTable
            // className="card"
            columns={columns}
            data={elaboracion}
            customStyles={customStyles}
            pagination
            // style={{ width: 'auto' }}
            //   rowClassName={(row) => {
            //     if (row.centrocoste === "Planta") {
            //       return classes.filaPlanta;
            //     } else {
            //       return "";
            //     }
            //   }}
            // paginationPerPage={15}
            // paginationRowsPerPageOptions={[15, 30, 45]}
            paginationComponentOptions={
                traduccion
            }
            dense
            // onRowClicked={clickenfilatabla}
            // paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
            conditionalRowStyles={conditionalRowStyles}
            // subHeader
            // subHeaderComponent={subHeaderComponentMemo}
            // selectableRows
            // persistTableHead

            // selectableRows
          />
        </div>
      ) : (
        <CargandoDatos cargando={cargando} />
      )}
    </>
  );
};

export default Elaboraciones;
