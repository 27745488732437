import React, { useState, useEffect } from "react";
import { DateTime } from "luxon";
import { createTheme, ThemeProvider } from "@material-ui/core";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import DateFnsUtils from "@date-io/luxon"; // choose your lib
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
// Recibe un periodo de tiempo (ayer, semana, esstemes, mespasado, esteano, anopasado,mes, ano)
// y la función a ejecutar en el componente padre cuando cambia el periodo
// y permite cambiar la selección y comunicarse con el padre a través de la función recibida
const theme = createTheme({
  palette: {
    // type: 'light',
    primary: {
      main: "#e3007e",
    },
    secondary: {
      main: "#f50057",
    },
  },
});
function SelectorFecha(props) {
  console.log(props);
  const [value, setValue] = useState('');
  const [fechainicio, setFechainicio] = useState("");
  const [fechafin, setFechafin] = useState("");
  useEffect(() => {
    setValue(props.periodo);
      switch (props.periodo) {
        // case "ayer":
        //   setFechainicio(
        //     DateTime.local().minus({ days: 1 }).toFormat("yyyyMMdd")
        //   );
        //   setFechafin(DateTime.local().minus({ days: 1 }).toFormat("yyyyMMdd"));
        //   props.cambioperiodo(
        //     DateTime.local().minus({ days: 1 }).toFormat("yyyyMMdd"),
        //     DateTime.local().minus({ days: 1 }).toFormat("yyyyMMdd")
        //   )
        //   break;

        case "semana":
          setFechainicio(
            DateTime.local().minus({ days: 7 }).toFormat("yyyyMMdd")
          );
          setFechafin(DateTime.local().minus({ days: 1 }).toFormat("yyyyMMdd"));
          props.cambioperiodo(
            DateTime.local().minus({ days: 7 }).toFormat("yyyyMMdd")
          ,
          DateTime.local().minus({ days: 1 }).toFormat("yyyyMMdd"),props.periodo
          )
          break;
        case "estemes":
          let fechafinal=DateTime.local().endOf("month").toFormat("yyyyMMdd")
          if (DateTime.local().endOf("month")>DateTime.local()){
            fechafinal=DateTime.local().minus({ days: 1 }).toFormat("yyyyMMdd")
          }
          setFechainicio(
            DateTime.local().startOf("month").toFormat("yyyyMMdd")
          );
          setFechafin(fechafinal);
          props.cambioperiodo(
            DateTime.local().startOf("month").toFormat("yyyyMMdd")
          ,
          // DateTime.local().minus({ days: 1 }).toFormat("yyyyMMdd")
          fechafinal,props.periodo
          )
          break;
        case "mespasado":
          setFechainicio(
            DateTime.local()
              .minus({ months: 1 })
              .startOf("month")
              .toFormat("yyyyMMdd")
          );
          setFechafin(
            DateTime.local()
              .minus({ months: 1 })
              .endOf("month")
              .toFormat("yyyyMMdd")
          );
          break;
        case "esteano":
          setFechainicio(DateTime.local().startOf("year").toFormat("yyyyMMdd"));
          setFechafin(DateTime.local().minus({ days: 1 }).toFormat("yyyyMMdd"));
          break;
        case "anopasado":
          setFechainicio(
            DateTime.local()
              .minus({ year: 1 })
              .startOf("year")
              .toFormat("yyyyMMdd")
          );
          setFechafin(
            DateTime.local()
              .minus({ year: 1 })
              .endOf("year")
              .toFormat("yyyyMMdd")
          );
          break;
        case "mes":
          setFechainicio(
            DateTime.local().minus({ days: 31 }).toFormat("yyyyMMdd")
          );
          setFechafin(DateTime.local().minus({ days: 1 }).toFormat("yyyyMMdd"));
          break;

        case "ano":
          setFechainicio(
            DateTime.local().minus({ days: 366 }).toFormat("yyyyMMdd")
          );
          setFechafin(DateTime.local().minus({ days: 1 }).toFormat("yyyyMMdd"));
          break;
        default:
          setFechainicio(
            DateTime.local().startOf("month").toFormat("yyyyMMdd")
          );
          setFechafin(DateTime.local().minus({ days: 1 }).toFormat("yyyyMMdd"));
      }
  }, [props.periodo]);

  const handleChange = (event) => {
    console.log('handleChange | '+event.target.value);
    setValue(event.target.value);
    props.cambiodeperiodo(event.target.value)
        // if (event.target.value == "ayer") {
        //   setFechainicio(
        //     DateTime.local().minus({ days: 1 }).toFormat("yyyyMMdd")
        //   );
        //   setFechafin(DateTime.local().minus({ days: 1 }).toFormat("yyyyMMdd"));
        //   props.cambioperiodo(
        //     DateTime.local().minus({ days: 1 }).toFormat("yyyyMMdd"),
        //     DateTime.local().minus({ days: 1 }).toFormat("yyyyMMdd")
        //   );
        // }
        if (event.target.value == "semana") {
          setFechainicio(
            DateTime.local().minus({ days: 7 }).toFormat("yyyyMMdd")
          );
          setFechafin(DateTime.local().minus({ days: 1 }).toFormat("yyyyMMdd"));
          props.cambioperiodo(
            DateTime.local().minus({ days: 7 }).toFormat("yyyyMMdd"),
            DateTime.local().minus({ days: 1 }).toFormat("yyyyMMdd"),
            event.target.value
          );
        }
        if (event.target.value == "estemes") {
          props.cambioperiodo(
            DateTime.local().startOf("month").toFormat("yyyyMMdd")
          ,
          // DateTime.local().minus({ days: 1 }).toFormat("yyyyMMdd")
          DateTime.local().endOf("month").toFormat("yyyyMMdd"),event.target.value
          )
        }
        if (event.target.value == "mespasado") {
          props.cambioperiodo
          (
            DateTime.local()
              .minus({ months: 1 })
              .startOf("month")
              .toFormat("yyyyMMdd")
          ,
          
            DateTime.local()
              .minus({ months: 1 })
              .endOf("month")
              .toFormat("yyyyMMdd"),event.target.value
          );
        }
        if (event.target.value == "esteano") {
          props.cambioperiodo(
            DateTime.local().startOf("year").toFormat("yyyyMMdd"),
          DateTime.local().minus({ days: 1 }).toFormat("yyyyMMdd"),event.target.value);
        }
        if (event.target.value == "anopasado") {
          setFechainicio(
            DateTime.local()
              .minus({ year: 1 })
              .startOf("year")
              .toFormat("yyyyMMdd")
          );
          setFechafin(
            DateTime.local()
              .minus({ year: 1 })
              .endOf("year")
              .toFormat("yyyyMMdd")
          );
          props.cambioperiodo(
            DateTime.local()
              .minus({ year: 1 })
              .startOf("year")
              .toFormat("yyyyMMdd"),
              DateTime.local()
              .minus({ year: 1 })
              .endOf("year")
              .toFormat("yyyyMMdd"),
          event.target.value);
        }
        if (event.target.value == "mes") {
          setFechainicio(
            DateTime.local().minus({ days: 31 }).toFormat("yyyyMMdd")
          );
          setFechafin(DateTime.local().minus({ days: 1 }).toFormat("yyyyMMdd"));

          props.cambioperiodo(
            DateTime.local().minus({ days: 31 }).toFormat("yyyyMMdd"),
            DateTime.local().minus({ days: 1 }).toFormat("yyyyMMdd"),
          event.target.value);
        }
        if (event.target.value == "90dias") {
          setFechainicio(
            DateTime.local().minus({ days: 91 }).toFormat("yyyyMMdd")
          );
          setFechafin(DateTime.local().minus({ days: 1 }).toFormat("yyyyMMdd"));

          props.cambioperiodo(
            DateTime.local().minus({ days: 91 }).toFormat("yyyyMMdd"),
            DateTime.local().minus({ days: 1 }).toFormat("yyyyMMdd"),
          event.target.value);
        }
        if (event.target.value == "180dias") {
          setFechainicio(
            DateTime.local().minus({ days: 181 }).toFormat("yyyyMMdd")
          );
          setFechafin(DateTime.local().minus({ days: 1 }).toFormat("yyyyMMdd"));

          props.cambioperiodo(
            DateTime.local().minus({ days:181 }).toFormat("yyyyMMdd"),
            DateTime.local().minus({ days: 1 }).toFormat("yyyyMMdd"),
          event.target.value);
        }
        if (event.target.value == "ano") {
          setFechainicio(
            DateTime.local().minus({ days: 366 }).toFormat("yyyyMMdd")
          );
          setFechafin(DateTime.local().minus({ days: 1 }).toFormat("yyyyMMdd"));
          props.cambioperiodo(DateTime.local().minus({ days: 366 }).toFormat("yyyyMMdd"),DateTime.local().minus({ days: 1 }).toFormat("yyyyMMdd"), event.target.value)
        }
  };
  function BpRadio(props) {
    return (
      <Radio
      sx={{
        color: '#000',
        '&.Mui-checked': {
          color: "#fff",
        },
      }}
       {...props}
      />
    );
  }
  return (
    <AppBar
      // style={{ background: '#e3007e' }}
      sx={{
        backgroundColor: "#e3007e",
      }}
      position="static"
    >
      <Toolbar>
        <FormControl>
          {/* <>
            Período: &nbsp; Del{" "}
            {DateTime.fromFormat(fechainicio, "yyyyMMdd")
              .setLocale("es")
              .toFormat("dd/MMMM/yyyy")}
            &nbsp; al{" "}
            {DateTime.fromFormat(fechafin, "yyyyMMdd")
              .setLocale("es")
              .toFormat("dd/MMMM/yyyy")}
          </> */}

          <RadioGroup
            value={value}
            onChange={handleChange}
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
          >
            {/* <FormControlLabel value="ayer" control={<Radio />} label="Ayer" /> */}
            <FormControlLabel
              value="semana"
              
              control={<BpRadio/>}
              label="Última semana"
            />
            <FormControlLabel
              value="estemes"
              color="success"
              control={<BpRadio />}
              label="Este mes"
            />
            <FormControlLabel
              value="mespasado"
              control={<BpRadio />}
              label="Mes pasado"
            />
            <FormControlLabel
              value="esteano"
              control={<BpRadio />}
              label="Este Año"
            />
            <FormControlLabel
              value="anopasado"
              control={<BpRadio />}
              label="Año pasado"
            />
            <FormControlLabel
              value="mes"
              control={<BpRadio />}
              label="Últimos 30 días"
            />
            <FormControlLabel
              value="90dias"
              control={<BpRadio />}
              label="Últimos 90 días"
            />
            <FormControlLabel
              value="180dias"
              control={<BpRadio />}
              label="Últimos 180 días"
            />
            <FormControlLabel
              value="ano"
              control={<BpRadio />}
              label="Último Año (365 días)"
            />
          </RadioGroup>
        </FormControl>
      </Toolbar>
    </AppBar>
  );
}
export default SelectorFecha;
