import React from "react";
import AxiosApi from "./../../servicios/axios.service";
import { Box, Grid, makeStyles} from "@material-ui/core";
import DataTable from "react-data-table-component";
import { colores,conditionalRowStyles,customStyles } from "../Auxiliares/Helpers";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import CargandoDatos from "../Auxiliares/CargandoDatos";
import Fab from "@mui/material/Fab";
import ReplayIcon from "@mui/icons-material/Replay";
import Typography from "@mui/material/Typography";
function CambiosPrecio() {


  const [cambios, setCambios] = React.useState([]);
  const [cargando, setCargando]=React.useState(false)

  const columns = [
    {
      name: "",
      selector: (row) => row.precio_nuevo > row.precio_anterior,
      sortable: true,
      width: "3rem",
    //   hide:'sm',
        grow: 1,
      // maxWidth: "20px",
      cell: (row) => (
        row.precio_nuevo > row.precio_anterior ? 
        <ArrowUpwardIcon style={{ color: 'green' }} /> : 
        <ArrowDownwardIcon style={{ color: 'red' }} />
      ),
    },
    {
      name: "Código",
      selector: (row) => row.codart,
      sortable: true,
    //   maxWidth: "20px",
      //   maxWidth: '2rem',
        grow: 1,
      // added line here
      // width: "90px",
      style:{
        flex:'unset',
      },
      headerStyle: (selector, id) => {
        return { textAlign: "center" }; // removed partial line here
      },
    },
    {
      name: "Descripcion",
      selector: (row) => row.descart,
      sortable: true,
      // width: "250px",
      // maxWidth: "250px",
        grow: 12,
        cell: (row) => {
          return <div style={{ fontSize: "0.8rem",textAlign:'left' }}>{row.descart}</div>;
        },
      // headerStyle: (selector, id) => {
      //   return { textAlign: "center", padding:0 }; // removed partial line here
      // },
    },
    // {
    //   name: "Precio anterior",
    //   selector: (row) => row.precio_anterior,
    //   sortable: true,
    //   hide:'sm',
    //   headerStyle: (selector, id) => {
    //     return { textAlign: "center"}; // removed partial line here
    //   },
    //   width: "120px",
    //   cell: (row) =>

    //   <div style={{ width: '80px', whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
    //   {Number(row.precio_anterior).toFixed(2).toLocaleString("es-ES", {
    //     style: "currency",
    //     currency: "EUR",
    //   }) + " €"}
    // </div>,
    // },
    {
      name: "Precio Nuevo",
      selector: (row) => row.precio_nuevo,
      sortable: true,
      // maxWidth: "120px",
         grow: 1,
      headerStyle: (selector, id) => {
        return { textAlign: "center"}; // removed partial line here
      },
      // width: "120px",
      grow: 1,
      cell: (row) =>
      <div style={{ 
        width: '80px', 
        whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
      {Number(row.precio_nuevo).toFixed(2).toLocaleString("es-ES", {
        style: "currency",
        currency: "EUR",
      }) + " €"}
    </div>,
    },
    {
      name: "",
      selector: (row) => row.car5,
      // width: "70px",
    //   hide:'sm',
        grow: 1,
        width: "4rem",
      maxWidth: "20px",
      // cell: (row) => (
      //   row.precio_nuevo > row.precio_anterior ? 
      //   <ArrowUpwardIcon style={{ color: 'green' }} /> : 
      //   <ArrowDownwardIcon style={{ color: 'red' }} />
      // ),
    },
    

  ]
  const fetchData = async () => {
    setCargando(true)
    try {
      const response = await AxiosApi.axiosApi.get(
        "https://empleados.diferente.es/api/articulos/cambiosdeprecio"
      );
      setCambios(response.data.cambios);
      setCargando(false)
      // console.log(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  React.useEffect(() => {


    fetchData();
  }, []);
  return (
    <>
      {!cargando && cambios.length > 0 ? (
        // <Grid
        //   columns={12}
        //   container
        //   justifyContent="center" // Centra horizontalmente los elementos hijos
        //   alignItems="center" // Centra verticalmente los elementos hijos
        //   style={{ minHeight: "10vh" }}
        // >
          // <Grid
          //   style={{ textAlign: "center", marginLeft:10 }}
          //   className="card"
          //   item
          //   xs={12}
          //   md={4}
          // >
          <div className="card_home">
            <Box sx={{ width: "100%",height:'100%' }}>
            <Fab
              onClick={() => {
                fetchData();
              }}
              sx={{ position: 'relative', top: 16, left: '90%' }}
              size="small"
              color="primary"
              aria-label="add"
            >
              <ReplayIcon />
            </Fab>
            <Typography variant="h6">
              Cambios de Precio
            </Typography>
          </Box>
            <DataTable
              // className="card"
              columns={columns}
              data={cambios}
              customStyles={customStyles}
              pagination
              // style={{ width: 'auto' }}
            //   rowClassName={(row) => {
            //     if (row.centrocoste === "Planta") {
            //       return classes.filaPlanta;
            //     } else {
            //       return "";
            //     }
            //   }}
              // paginationPerPage={15}
              // paginationRowsPerPageOptions={[15, 30, 45]}
              // paginationComponentOptions={
              //     paginationComponentOptions
              // }
              dense
              // onRowClicked={clickenfilatabla}
              // paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
              conditionalRowStyles={conditionalRowStyles}
              // subHeader
              // subHeaderComponent={subHeaderComponentMemo}
              // selectableRows
              // persistTableHead

              // selectableRows
            />
            </div>
          // </Grid>

        // </Grid>

      ) : (
        <CargandoDatos cargando={cargando} />
      )}
    </>
  );
}

export default CambiosPrecio;
