import Ventaseurostienda from "./../component/VentasEurosTienda";
import Clientestienda from "./../component/ClientesTienda";
import DataGdridVentasClienets from "./../component/DataGridVentasClientes";
import authService from "../servicios/auth.service";
import Margenes from "../component/Margenes";
import { Box, Grid, Paper } from "@material-ui/core";
import { styled } from "@material-ui/styles";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import CambiosPrecio from "../component/Home/CambiosPrecio";
import Encargos from "../component/Home/Encargos";
import Elaboraciones from "../component/Home/Elaboraciones";
// import roles from "../recursos/tipousuarios";
import React, { useState, useEffect } from "react";
import EstadoTiendas from "../component/EstadoTiendas/EstadoTiendas";
import Ventasdiarias from "../component/Ventasdiarias/Ventasdiarias";
function About() {
  // console.warn(roles);
  const [esAdministrador, setEsAdmininistrador] = useState(
    authService.getCurrentUser().rol === "admin"
  );
  const [esEncargado, setEsEncargado] = useState(
    authService.getCurrentUser().rol === "encargado"
  );
  // const Item = styled(Paper)(({ theme }) => ({
  //   backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  //   ...theme.typography.body2,
  //   padding: theme.spacing(1),
  //   textAlign: "center",
  //   color: theme.palette.text.secondary,
  // }));
  useEffect(() => {
    document.title = "Portal del Empleado DiFERENTE";
  }, []);
  return (
    <>
      {/* <Grid style={{ display: "flex", alignContent: "center", width: "100%", margin:10 }}> */}
      <Box sx={{ flexGrow: 1, m: 2 }}>
        {authService.comprobarRol(0) ? (
          <>
            <Grid  item xs={12} md={12} lg={12}>
              {" "}
              <Ventasdiarias />
            </Grid>
          </>
        ) : (
          <></>
        )}
        <Grid
          spacing={2}
          // style={{ display: "flex", alignContent: "center", width: "100%", margin:10 }}
          sx={{ p: 5 }}
          alignContent="center"
          // alignItems="top"
          justifyContent="center"
          item
          container
          // item
          md={12}
          lg={12}
        >
          <Grid item style={{ padding: "0.3rem" }} xs={12} md={6} lg={4}>
            {" "}
            <CambiosPrecio />
          </Grid>
          <Grid item style={{ padding: "0.3rem" }} xs={12} md={6} lg={4}>
            <Elaboraciones />
          </Grid>
          <Grid item style={{ padding: "0.3rem" }} xs={12} md={6} lg={4}>
            {" "}
            <Encargos />
          </Grid>
        </Grid>
      </Box>
      {/* </Grid> */}
      {authService.comprobarRol(0) ? (
        <Box sx={{ flexGrow: 1, m: 2 }}>
          <EstadoTiendas codalm="V" />
          <EstadoTiendas codalm="S" />
        </Box>
      ) : (
        <></>
      )}

      {esEncargado ? (
        // Solo visible para encargados
        <></>
      ) : (
        <></>
      )}
    </>
  );
}
export default About;
