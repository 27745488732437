import React, { useEffect, useState } from "react";
import { useMediaQuery, useTheme } from "@mui/material";
import {
  ThemeProvider,
  createMuiTheme,
  makeStyles,
} from "@material-ui/core/styles";
import HomeIcon from "@mui/icons-material/Home";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Link } from "react-router-dom";
import AxiosApi from "./../servicios/axios.service";
import AuthService from "./../servicios/auth.service";
import { useMiContexto } from "../contexto";
import Logo from "../recursos/logo-negro.png";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import { initial } from "lodash";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
const useStyles = makeStyles((theme) => ({
  logo: {
    flexGrow: 1,
    cursor: "pointer",
  },
  link: {
    textDecoration: "none",
    color: "white",
    fontSize: "20px",
    marginLeft: theme.spacing(4),
    "&:hover": {
      color: "yellow",
      borderBottom: "1px solid white",
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  hide: {
    display: "none",
  },
}));
const pages = ["Products", "Pricing", "Blog"];
const paginas = [];
const enlacesperfil = [
  { texto: "perfil", link: "perfil" },
  {
    texto: "Salir",
    link: "Logout",
  },
];
const settings = ["Profile", "Account", "Dashboard", "Logout"];
function Navbar2024() {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [perfil, setPerfil] = useState({});
  const [currentUser, setCurrentUser] = useState({});
  const { miEstado } = useMiContexto();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  // const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    handleCloseNavMenu();
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const perfilResponse = await AxiosApi.axiosApi.get(
          "https://empleados.diferente.es/api/miperfil"
        );
        setCurrentUser(AuthService.getCurrentUser());
        setPerfil(perfilResponse.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [miEstado]);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    AuthService.logout();
  };

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const logOut = () => {
    AuthService.logout();
    // window.location.reload()
  };
  const enlaces = [
    {
      titulo: "Online",
      enlace: "/online",
    },
    {
      titulo: "Clientes",
      enlace: "/clientes",
    },
    {
      titulo: "Artículos",
      enlace: "/articulos",
    },
    {
      titulo: "Encargos",
      enlace: "/encargos",
    },
    {
      titulo: "Pedidos",
      enlace: "/pedidos",
    },
    {
      titulo: "Videos",
      enlace: "/videos",
    },
  ];
  const nivel1 = ["*", "admin"];
  const nivel2 = ["*", "admin", "adjuntocontrol"];
  const nivel3 = ["*", "admin", "adjuntocontrol", "encargado"];
  const EnlaceNavbar = (item) => {
    console.log(item);
  };
  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
           <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            <img
              alt=""
              src={Logo}
              // width="30"
              height="45"
              className="d-inline-block align-top"
            />
          </Typography>
          {/* Inicio del menú responsive */}
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {enlaces.map((item, index) => {
                // console.log(item);
                return (
                  // <MenuItem>{item.titulo}</MenuItem>

                  <MenuItem
                    sx={{ my: 2, color: "#e3007e", display: "block" }}
                    as={Link}
                    to={item.enlace}
                    key={index}
                    onClick={handleCloseNavMenu}
                  >
                    <Typography>{item.titulo}</Typography>
                  </MenuItem>
                );
              })}
              {perfil &&
              Object.keys(perfil).length != 0 &&
              nivel1.includes(perfil.OBSERVACIONES.rol) ? (
                
                  <MenuItem
                    onClick={handleCloseNavMenu}
                    sx={{ my: 2, color: "#e3007e", display: "block" }}
                    as={Link}
                    to="/analisis"
                  >
                    Analisis
                  </MenuItem>

        
              ) : (
                <Box></Box>
              )}

              {perfil && Object.keys(perfil).length != 0 ? (
                <Box>
                  {nivel2.includes(perfil.OBSERVACIONES.rol) ? (
                    <Box>
                      <ListItem button onClick={handleClick}>
                        {/* <ListItemIcon>{item.icon}</ListItemIcon> */}
                        <ListItemText primary={"Administracion"} />
                        {open ? <ExpandLess /> : <ExpandMore />}
                      </ListItem>
                      <Collapse in={open} timeout="auto" unmountOnExit>
                        <List component="div">
                          {nivel1.includes(perfil.OBSERVACIONES.rol) ? (
                            <MenuItem
                              onClick={handleClose}
                              sx={{ my: 2, color: "#e3007e", display: "block" }}
                              as={Link}
                              to="usuarios"
                            >
                              Usuarios
                            </MenuItem>
                          ) : (
                            <Box></Box>
                          )}
                          {nivel2.includes(perfil.OBSERVACIONES.rol) ? (
                            <MenuItem
                              sx={{ my: 2, color: "#e3007e", display: "block" }}
                              onClick={handleClose}
                              as={Link}
                              to="fichajesadmin"
                            >
                              Fichajes
                            </MenuItem>
                          ) : (
                            <Box></Box>
                          )}
                        </List>
                      </Collapse>
                    </Box>
                  ) : (
                    <Box></Box>
                  )}
                </Box>
              ) : (
                <Box></Box>
              )}
            </Menu>
          </Box>
          {/* Fin del menú responsive */}
          {/* Logo en mitad de la barra de navegación */}
          <Box
            sx={{
              xs: "flex",
              md: "none",
              // minHeight: '100vh',
              alignItems: "center",
              flexGrow: 1,
            }}
          >
            <Box
              component={Link}
              to="/"
              sx={{
                display: { justifyContent: "center", xs: "flex", md: "none" },
                mr: 1,
              }}
            >
              <img
                alt=""
                src={Logo}
                // width="30"

                height="60"
                // className="d-inline-block align-top"
              />
            </Box>
          </Box>
          {/* Fin del logo en la barra de navegación */}

          {/* El Box siguiente muestra los elementos del menu en pantallas grandes */}
          <Box sx={{ flexGrow: "20", display: { xs: "none", md: "flex" } }}>
            {enlaces.map((item, index) => {
              // console.log(item);
              return (
                <Button
                  sx={{ my: 2, color: "white", display: "block" }}
                  as={Link}
                  to={item.enlace}
                  key={index}
                >
                  {item.titulo}
                </Button>
              );
            })}

            {perfil && Object.keys(perfil).length != 0 ? (
              <>
                {nivel1.includes(perfil.OBSERVACIONES.rol) ? (
                  <>
                    <Button
                      onClick={handleCloseNavMenu}
                      sx={{ my: 2, color: "white", display: "block" }}
                      as={Link}
                      to="/analisis"
                    >
                      Análisis
                    </Button>

                  </>
                ) : (
                  <></>
                )}

                {nivel2.includes(perfil.OBSERVACIONES.rol) ? (
                  <>
                    <Button
                      id="basic-button"
                      sx={{ my: 2, color: "white", display: "block" }}
                      aria-controls={open ? "basic-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      onClick={handleClick}
                    >
                      Administracion
                    </Button>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      {nivel1.includes(perfil.OBSERVACIONES.rol) ? (
                        <MenuItem
                          onClick={handleClose}
                          sx={{ my: 2, color: "#e3007e", display: "block" }}
                          as={Link}
                          to="usuarios"
                        >
                          Usuarios
                        </MenuItem>
                      ) : (
                        <></>
                      )}
                      {nivel2.includes(perfil.OBSERVACIONES.rol) ? (
                        <MenuItem
                          sx={{ my: 2, color: "#e3007e", display: "block" }}
                          onClick={handleClose}
                          as={Link}
                          to="fichajesadmin"
                        >
                          Fichajes
                        </MenuItem>
                      ) : (
                        <></>
                      )}
                    </Menu>
                  </>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}
          </Box>
          {/* Fin del menu en pantallas grandes */}

          {perfil && perfil.CODCLI ? (
            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar
                    alt={perfil.NOMCLI}
                    src={
                      "https://empleados.diferente.es/public/" +
                      perfil.OBSERVACIONES.imagen
                    }
                    // sx={{ width: 36, height: 36 }}
                  />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <MenuItem
                  component={Link}
                  to="/perfil"
                  onClick={handleCloseUserMenu}
                >
                  <Typography textAlign="center">{perfil.NOMCLI}</Typography>
                </MenuItem>

                <MenuItem component={Link} to="/logout" onClick={logOut}>
                  <Typography textAlign="center">Salir</Typography>
                </MenuItem>
              </Menu>
            </Box>
          ) : (
            <></>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default Navbar2024;
