import React from "react";
import AxiosApi from "./../../servicios/axios.service";
import { Box, Grid, makeStyles } from "@material-ui/core";
import DataTable from "react-data-table-component";
import {
  colores,
  conditionalRowStyles,
  customStyles,
} from "../Auxiliares/Helpers";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import CargandoDatos from "../Auxiliares/CargandoDatos";
import Fab from "@mui/material/Fab";
import ReplayIcon from "@mui/icons-material/Replay";
import Typography from "@mui/material/Typography";
function Encargos() {
  const [encargos, setEncargos] = React.useState([]);
  const [cargando, setCargando] = React.useState(false);

  const columns = [
    {
      name: "Método",
      selector: (row) => row.metodo,
      sortable: true,
      //   maxWidth: "20px",
      //   maxWidth: '2rem',
      grow: 10,
      // added line here
      // width: "90px",
      style: {
        flex: "unset",
      },
      headerStyle: (selector, id) => {
        return { textAlign: "center" }; // removed partial line here
      },
    },
    {
      name: "Fecha",
      selector: (row) => row.fecha_hora,
      sortable: true,
      // width: "250px",
      //   maxWidth: "60px",
      grow: 12,
    },

    {
      name: "Importe",
      selector: (row) => row.importe,
      sortable: true,
      // maxWidth: "20px",
      // grow: 3,
      // headerStyle: (selector, id) => {
      //   return { textAlign: "center"}; // removed partial line here
      // },
      // width: "120px",
      grow: 4,
      cell: (row) => (
        <div
          style={{
            width: "80px",
            whiteSpace: "pre-wrap",
            wordWrap: "break-word",
          }}
        >
          {Number(row.importe).toFixed(2).toLocaleString("es-ES", {
            style: "currency",
            currency: "EUR",
          }) + " €"}
        </div>
      ),
    },
  ];
  const fetchData = async () => {
    setCargando(true);
    setEncargos([])
    try {
      const response = await AxiosApi.axiosApi.get(
        "https://empleados.diferente.es/api/encargos/listarproximos"
      );
      setEncargos(response.data.encargos);
      setCargando(false);
      // console.log(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  React.useEffect(() => {
    fetchData();
  }, []);
  return (
    <>
      {!cargando  ? (
        // <Grid
        //   style={{ textAlign: "center" }}
        //   className="card"
        //   item
        //   xs={12}
        //   md={4}
        //   lg={4}
        // >
        <div className="card_home">
        
          <Box sx={{ width: "99%" }}>
            <Fab
              onClick={() => {
                fetchData();
              }}
              sx={{ position: 'relative', top: 16, left: '90%' }}
              size="small"
              color="primary"
              aria-label="add"
            >
              <ReplayIcon />
            </Fab>
            <Typography variant="h6">
              Encargos de los próximos 7 días
            </Typography>
          </Box>

          <DataTable
            // className="card"
            columns={columns}
            data={encargos}
            customStyles={customStyles}
            pagination
            // style={{ width: 'auto' }}
            //   rowClassName={(row) => {
            //     if (row.centrocoste === "Planta") {
            //       return classes.filaPlanta;
            //     } else {
            //       return "";
            //     }
            //   }}
            // paginationPerPage={15}
            // paginationRowsPerPageOptions={[15, 30, 45]}
            // paginationComponentOptions={
            //     paginationComponentOptions
            // }
            dense
            // onRowClicked={clickenfilatabla}
            // paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
            conditionalRowStyles={conditionalRowStyles}
            // subHeader
            // subHeaderComponent={subHeaderComponentMemo}
            // selectableRows
            // persistTableHead

            // selectableRows
          />
          </div>
        // </Grid>
      ) : (
        <CargandoDatos cargando={cargando} />
      )}
    </>
  );
}

export default Encargos;
